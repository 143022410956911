<template>
  <div>
    <p>
      The application for the property tax refund must be properly
      completed and filed with the Department of Revenue or the County
      Treasurer of your county
      <span class="red--text">no later than the first Monday in June of every year</span>. Be sure to fill in all
      applicable spaces on the entire form. If a particular item is not applicable, be sure to mark it as such; do not
      leave items blank. Please attach all supporting documents for income.<br />INCOMPLETE FORMS WILL RESULT IN THE
      DENIAL OF REFUND UNDER THIS PROGRAM.
    </p>
    <h2 class="text-subtitle-1 font-weight-medium primary--text">
      Wyoming Residency Requirement
    </h2>
    <p>
      1.) Checkmark if you qualify under the terms of the residency requirement set forth in Wyoming Residency
      Requirements.
    </p>
    <h2 class="text-subtitle-1 font-weight-medium primary--text">
      Property Tax Paid On Applicant's Primary Residence
    </h2>
    <p>
      2.) The house subject to the application must be the applicant's principal residence and occupied by the owner not
      less than 9 months of the applicable tax year.<br />
      3.) In the space provided enter the total amount of the property tax bill for the subject property, as reflected on
      the bill you or your mortgage company received last September. Attach a copy of the bill to this application
      form.<br />
      4.) If any part of the residence is used for business purposes and you prepare a Schedule 1 - Business Income
      statement or Farm Income statement for your income tax return, enter the amount of property tax you deducted on
      these schedules in the space provided. Provide proper forms showing this expense. All taxable and non-taxable income
      applies.
    </p>
    <h2 class="text-subtitle-1 font-weight-medium primary--text">
      Annual Income Report
    </h2>
    <p>
      5.) Complete the income section, listing total income for all adult members of the household in the spaces provided
      on the application form. Be sure to enter the grand total for all members' incomes combined in the space provided.
      If there are more than five adult members of the household, attach additional sheets.
      <br />
      6.) Specific Instructions for Items Marked (1) on the Income Section of the Application:
    <div class="ml-4  mt-n4">
      a.) Total Income: For each adult household member, report the amount from the indicated line of their federal income
      tax return. Use the appropriate line for each member.
      <br />b.) Nontaxable interest: Enter the amount, if any, from line 2a of the Form 1040 of each applicable household
      member and enter on line provided.
      <br />c.) Nontaxable portion of IRA or other pension distributions: Subtract the amounts on lines 4b from the
      corresponding amounts on lines 4a of the Form 1040 or 1040 SR and or subtract the amounts on 5b from the amounts on
      5a and enter results on the line provided.
      <br />d.) Nontaxable portion of Social Security benefits: Subtract the amount on line 6b from the corresponding
      amount on line 6a of the Form 1040 or 1040 SR and enter the result on the line provided.
    </div>
    </p>
    <h2 class="text-subtitle-1 font-weight-medium primary--text">
      Value Of Household Assets
    </h2>
    <p>
      7.) Checkmark if the value of the assets listed, owned by each adult member of the household is
      <span class="red--text">LESS THAN {{ currentLuPeriod.AssetMaximum | formatMoney }} or in any case that your property
        tax bill exceeds 10% of your total household income reported</span>.
    </p>
    <h2 class="text-subtitle-1 font-weight-medium primary--text">
      Certification
    </h2>
    <p>
      8.) Be sure to read the certification statement very carefully, and sign and date the application in the spaces
      provided.
      <br />
      <span class="red--text">IMPORTANT DOCUMENTATION REQUIRED</span>
      <br />
      9.) Attach copies of:
    </p>
    <div class="ml-4  mt-n4">
      a.) The federal income tax returns for all adult members of the household, no handwritten copies will be accepted.
      Obtain copy transcript from IRS if necessary.<br />
      b.) Schedule 1 of the federal income tax returns, if applicable (see item 4, above) or any other documents showing
      business expense calculated.<br />
      c.) The property tax bill on the subject property<br />
      d.) Property tax payment receipts on the subject property<br />
      e.) A copy of the year-end statement of social security, pension and all other income received for any adult member
      of the household who was not required to file a federal income tax return for the applicable property tax year.
    </div>
    <p class="red--text text-subtitle-2 mt-6">
      CALL DEPARTMENT OF REVENUE FOR ASSISTANCE: 307-777-7320
    </p>

    <p>
      For paper application and additional information, please visit the
      <a href="https://revenue.wyo.gov/divisions/administrative-services/property-tax-refund-program">Property Tax Refund
        Program Homepage</a>
    </p>
  </div>
</template>
<script>
import { get, sync, commit, call } from 'vuex-pathify'
export default {
  page: {
    title: 'Filing Instructions',
  },
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
    ...get('luPeriod', ['currentLuPeriod']),
  },
  methods: {
    ...call('luPeriod', ['loadCurrentLuPeriod']),
  },
  created () {
    if (!this.currentLuPeriod) {
      this.loadCurrentLuPeriod()
    }
  },
  watch: {
  },
}
</script>
<style scoped></style>
