var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('h2',{staticClass:"text-subtitle-1 font-weight-medium primary--text"},[_vm._v(" Wyoming Residency Requirement ")]),_c('p',[_vm._v(" 1.) Checkmark if you qualify under the terms of the residency requirement set forth in Wyoming Residency Requirements. ")]),_c('h2',{staticClass:"text-subtitle-1 font-weight-medium primary--text"},[_vm._v(" Property Tax Paid On Applicant's Primary Residence ")]),_vm._m(1),_c('h2',{staticClass:"text-subtitle-1 font-weight-medium primary--text"},[_vm._v(" Annual Income Report ")]),_vm._m(2),_vm._m(3),_c('p'),_c('h2',{staticClass:"text-subtitle-1 font-weight-medium primary--text"},[_vm._v(" Value Of Household Assets ")]),_c('p',[_vm._v(" 7.) Checkmark if the value of the assets listed, owned by each adult member of the household is "),_c('span',{staticClass:"red--text"},[_vm._v("LESS THAN "+_vm._s(_vm._f("formatMoney")(_vm.currentLuPeriod.AssetMaximum))+" or in any case that your property tax bill exceeds 10% of your total household income reported")]),_vm._v(". ")]),_c('h2',{staticClass:"text-subtitle-1 font-weight-medium primary--text"},[_vm._v(" Certification ")]),_vm._m(4),_vm._m(5),_c('p',{staticClass:"red--text text-subtitle-2 mt-6"},[_vm._v(" CALL DEPARTMENT OF REVENUE FOR ASSISTANCE: 307-777-7320 ")]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" The application for the property tax refund must be properly completed and filed with the Department of Revenue or the County Treasurer of your county "),_c('span',{staticClass:"red--text"},[_vm._v("no later than the first Monday in June of every year")]),_vm._v(". Be sure to fill in all applicable spaces on the entire form. If a particular item is not applicable, be sure to mark it as such; do not leave items blank. Please attach all supporting documents for income."),_c('br'),_vm._v("INCOMPLETE FORMS WILL RESULT IN THE DENIAL OF REFUND UNDER THIS PROGRAM. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 2.) The house subject to the application must be the applicant's principal residence and occupied by the owner not less than 9 months of the applicable tax year."),_c('br'),_vm._v(" 3.) In the space provided enter the total amount of the property tax bill for the subject property, as reflected on the bill you or your mortgage company received last September. Attach a copy of the bill to this application form."),_c('br'),_vm._v(" 4.) If any part of the residence is used for business purposes and you prepare a Schedule 1 - Business Income statement or Farm Income statement for your income tax return, enter the amount of property tax you deducted on these schedules in the space provided. Provide proper forms showing this expense. All taxable and non-taxable income applies. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 5.) Complete the income section, listing total income for all adult members of the household in the spaces provided on the application form. Be sure to enter the grand total for all members' incomes combined in the space provided. If there are more than five adult members of the household, attach additional sheets. "),_c('br'),_vm._v(" 6.) Specific Instructions for Items Marked (1) on the Income Section of the Application: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-4 mt-n4"},[_vm._v(" a.) Total Income: For each adult household member, report the amount from the indicated line of their federal income tax return. Use the appropriate line for each member. "),_c('br'),_vm._v("b.) Nontaxable interest: Enter the amount, if any, from line 2a of the Form 1040 of each applicable household member and enter on line provided. "),_c('br'),_vm._v("c.) Nontaxable portion of IRA or other pension distributions: Subtract the amounts on lines 4b from the corresponding amounts on lines 4a of the Form 1040 or 1040 SR and or subtract the amounts on 5b from the amounts on 5a and enter results on the line provided. "),_c('br'),_vm._v("d.) Nontaxable portion of Social Security benefits: Subtract the amount on line 6b from the corresponding amount on line 6a of the Form 1040 or 1040 SR and enter the result on the line provided. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 8.) Be sure to read the certification statement very carefully, and sign and date the application in the spaces provided. "),_c('br'),_c('span',{staticClass:"red--text"},[_vm._v("IMPORTANT DOCUMENTATION REQUIRED")]),_c('br'),_vm._v(" 9.) Attach copies of: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-4 mt-n4"},[_vm._v(" a.) The federal income tax returns for all adult members of the household, no handwritten copies will be accepted. Obtain copy transcript from IRS if necessary."),_c('br'),_vm._v(" b.) Schedule 1 of the federal income tax returns, if applicable (see item 4, above) or any other documents showing business expense calculated."),_c('br'),_vm._v(" c.) The property tax bill on the subject property"),_c('br'),_vm._v(" d.) Property tax payment receipts on the subject property"),_c('br'),_vm._v(" e.) A copy of the year-end statement of social security, pension and all other income received for any adult member of the household who was not required to file a federal income tax return for the applicable property tax year. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" For paper application and additional information, please visit the "),_c('a',{attrs:{"href":"https://revenue.wyo.gov/divisions/administrative-services/property-tax-refund-program"}},[_vm._v("Property Tax Refund Program Homepage")])])
}]

export { render, staticRenderFns }